import React, { FC } from 'react';
import classNames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import Breadcrumbs from 'components/Breadcrumbs';

import { BannerProps } from './models';
import './Banner.scss';
import './BannerExtends.scss';
import './BannerRTL.scss';

const Banner: FC<BannerProps> = ({
  title,
  url,
  withBreadcrumbs = true,
  image,
  imageAlt = 'banner image',
  backgroundColor,
  variant,
  className,
  children,
  inFirstViewPort = false,
  isExtendedBanner,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div
      className={classNames('banner', className, {
        [`banner--${variant}`]: variant,
        banner__extended: isExtendedBanner,
      })}
      style={{
        backgroundColor,
      }}
    >
      {image?.childImageSharp ? (
        <GatsbyImage
          className={classNames('banner__image', {
            [`banner__image--${variant}`]: variant,
          })}
          alt={imageAlt}
          fluid={image?.childImageSharp.fluid}
          isLazyLoading={!inFirstViewPort}
        />
      ) : null}
      {url && withBreadcrumbs && !isMobile ? (
        <Breadcrumbs
          light
          url={url}
          className={classNames('banner__breadcrumbs', {
            [`banner__breadcrumbs--${variant}`]: variant,
          })}
        />
      ) : null}
      <DangerouslySetInnerHtml
        className={classNames('banner__title', {
          [`banner__title--${variant}`]: variant,
        })}
        html={title}
      />
      <div
        className={classNames('banner__children-wrapper', {
          [`banner__children-wrapper--${variant}`]: variant,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Banner;
