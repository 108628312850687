import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const BreadcrumbsListItem = ({ item: { link, name }, i, itemArray, light }) => (
  <li
    className={classNames('breadcrumbs-item-wrapper', {
      'breadcrumbs-item-wrapper--light': light,
    })}
    key={link}
    itemScope
    itemProp="itemListElement"
    itemType="http://schema.org/ListItem"
  >
    {i === itemArray.length - 1 ? (
      <span className="breadcrumbs-item--current-element" itemProp="name">
        {name}
      </span>
    ) : (
      <>
        <Link
          className="breadcrumbs-item-link"
          to={link === '/' ? `${link}` : `${link}/`}
          itemProp="item"
        >
          <span itemProp="name">{name}</span>
        </Link>
        <div className="chevron-arrow-right" />
      </>
    )}
    <meta itemProp="position" content={i + 1} />
  </li>
);

export default BreadcrumbsListItem;
