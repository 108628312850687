import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';

import { IShopCardProps } from './model';

import './ShopCard.scss';

const ShopCard: FC<IShopCardProps> = ({
  card: { properties: cardproperties, localImage },
  isLazyLoading,
}) => (
  <div className="shop-card" data-testid="shop-card">
    {localImage?.childImageSharp ? (
      <GatsbyImage
        isLazyLoading={isLazyLoading}
        alt={cardproperties.imageAltLabel}
        fluid={localImage?.childImageSharp.fluid}
        wrapperClasses="shop-card__image"
      />
    ) : null}
    <div className="shop-card__content">
      <h3 className="shop-card__title">{cardproperties.title}</h3>
      {cardproperties?.button?.map(({ properties: { buttonLink, buttonText, ariaLabel } }) => (
        <Button
          key={`${cardproperties.title}${buttonLink[0].url}`}
          ariaLabel={ariaLabel || cardproperties.title}
          btnColorVariant="dark"
          link={
            buttonLink[0].queryString
              ? buttonLink[0].url + buttonLink[0].queryString
              : buttonLink[0].url
          }
          classes="shop-card__button"
        >
          {buttonText}
        </Button>
      ))}
    </div>
  </div>
);

export default ShopCard;
