import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import Banner from 'components/Banner';
import ShopCard from 'components/ShopCard';

import { IBuyOnlineProps } from './model';

import './BuyOnlinePageMain.scss';
import './BuyOnlinePageExtends.scss';

const BuyOnlinePage: FC<IBuyOnlineProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoBuyOnline: { properties, url, defaultCompositions },
  },
}) => {
  const {
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaDescription,
    seoExternalHreflangs,
    seoCanonicalUrl,
  } = properties;
  const { banner, title, shopCard } = properties;
  const isLazyLoading = true;

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
      }}
    >
      <Banner {...banner[0].properties} url={url} className="buy-online-page-banner" />
      <div className="buy-online-page">
        <h2 className="buy-online-page__title">{title}</h2>
        <div className="buy-online-page__content">
          {shopCard
            ? shopCard.map((card) => (
                <ShopCard
                  key={`${card.properties.title}`}
                  card={card}
                  isLazyLoading={isLazyLoading}
                />
              ))
            : null}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BuyOnlinePage($url: String = "", $lang: String) {
    umbracoBuyOnline(url: { eq: $url }, lang: { eq: $lang }) {
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        title
        banner {
          properties {
            backgroundColour
            title
            variant
          }
        }
        shopCard {
          localImage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          properties {
            title
            imageAltLabel
            button {
              properties {
                buttonLink {
                  url
                  queryString
                }
                buttonText
                ariaLabel
              }
            }
          }
        }
      }
      url
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default BuyOnlinePage;
