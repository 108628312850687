import React, { FC } from 'react';
import classNames from 'classnames';

import './Breadcrumbs.scss';
import './BreadcrumbsExtends.scss';
import 'gatsby-theme-husky/src/components/Breadcrumbs/BreadcrumbsRTL.scss';
import { IBreadcrumbsProps } from 'gatsby-theme-husky/src/components/Breadcrumbs/models';
import BreadcrumbsList from './BreadcrumbsList';

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ url, light = false, className }) => (
  <div className={classNames('breadcrumbs', className)}>
    <BreadcrumbsList {...{ url, light }} />
  </div>
);

export default Breadcrumbs;
