import React, { useMemo } from 'react';
import { useAllSitePageContext } from 'gatsby-theme-husky/src/context/allSitePage';
import BreadcrumbsListItem from './BreadcrumbsListItem';

const BreadcrumbsList = ({ url, light }) => {
  const { getPageNameByUrl } = useAllSitePageContext();

  const links = useMemo(() => {
    const breadcrumbsUrl = url.startsWith('/') ? url : `/${url}`;
    const urlParts = [...(breadcrumbsUrl.match(/\/[^\\/]+/g) || [])];

    urlParts[0]?.length === 3 ? null : urlParts.unshift('/');

    return urlParts?.map((_, index) => {
      const shouldRemoveRootLangPageSlash = urlParts[0] === '/' && index > 0;
      const breadcrumbUrl = urlParts
        .slice(shouldRemoveRootLangPageSlash ? 1 : 0, index + 1)
        .join('');
      const name = getPageNameByUrl(breadcrumbUrl);

      return {
        link: breadcrumbUrl,
        name,
      };
    });
  }, [url, getPageNameByUrl]);

  return (
    <>
      {links.length ? (
        <div className="banner-text__breadcrumbs">
          <ul className="breadcrumbs-wrapper" itemScope itemType="http://schema.org/BreadcrumbList">
            {links.map((item, i, itemArray) => (
              <BreadcrumbsListItem key={item.link} {...{ item, i, itemArray, light }} />
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default BreadcrumbsList;
